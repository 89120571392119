import { checkEmail } from '@shef/validators';
import { isFacebookBrowser, isInstagramBrowser } from 'common/BrowserUtilities';
import { ShefErrorCode } from 'common/errors/ShefErrors';
import { ClientEvents } from 'common/events/ClientEvents';
import { isLeft } from 'fp-ts/lib/Either';
import { GraphQLError } from 'graphql';
import { includes, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CurrentUserFieldsFragment,
  OAuthLoginType,
  OAuthMissingFieldsResult,
  useGetRegisteredEmailLazyQuery,
} from 'src/gqlReactTypings.generated.d';
import { useFeatureFlag } from 'src/shared/design-system/FeatureFlag';
import { LabeledTextInput } from 'src/shared/design-system/Input/LabeledTextInput/LabeledTextInput';
import { useTracker } from 'src/shared/hooks/useTracker';
import Button, { ButtonType } from 'web-common/src/shared/design-system/components/Button';
import { Icon, IconColor } from 'web-common/src/shared/design-system/components/Icon';
import { AppleLogin } from '../oAuth/AppleLogin';
import { FacebookLoginV2 } from '../oAuth/FacebookLoginV2';
import { GoogleLoginV2 } from '../oAuth/GoogleLoginV2';
import { GoogleLoginV3 } from '../oAuth/GoogleLoginV3';

enum AuthType {
  GOOGLE,
  FACEBOOK,
  EMAIL,
  APPLE,
}

interface LogInOrSignUpModalEntryFormError {
  type: AuthType;
  errorMessage: string;
}

interface LogInOrSignUpModalEntryFormProps {
  email: string;
  onEmailChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onEmailContinueSuccess: (email: string, isRegisteredUser: boolean) => void;
  onOAuthSuccess?: (isExistingUser: boolean, currentUser: CurrentUserFieldsFragment) => void;
  onOAuthMissingFields: (type: OAuthLoginType, token: string, missingFields: OAuthMissingFieldsResult) => void;
  requireZipCode?: boolean;
  formCloseButton: JSX.Element | null;
  signUpEntryPoint?: string | null;
  continueCTA?: string | null;
  formRef: React.RefObject<HTMLFormElement>;
}

const isGraphQLError = (error: Error): error is GraphQLError => 'extensions' in error;

const ALLOW_LISTED_ERROR_CODES = [ShefErrorCode.FACEBOOK_SIGN_UP_NO_EMAIL, ShefErrorCode.GOOGLE_BROWSER_NOT_SUPPORTED];
const isAllowListedError = (error: Error): boolean => {
  if (!isGraphQLError(error)) {
    return false;
  }

  return includes(ALLOW_LISTED_ERROR_CODES, error.extensions.code);
};

export const ErrorMessageDisplay: React.FC<{ errorMessage: string }> = ({ errorMessage }) => (
  <div className='flex items-center gap-1 bg-sourdough pt-2 text-label-400 text-error'>
    <Icon name='alert' color={IconColor.ERROR} />
    {errorMessage}
  </div>
);

export const LogInOrSignUpModalEntryForm: React.FC<LogInOrSignUpModalEntryFormProps> = ({
  email,
  onEmailChange,
  onEmailContinueSuccess,
  onOAuthSuccess,
  onOAuthMissingFields,
  requireZipCode,
  formCloseButton,
  signUpEntryPoint,
  continueCTA = 'Continue',
  formRef,
}) => {
  const [error, setError] = useState<LogInOrSignUpModalEntryFormError | undefined>();
  const [emailError, setEmailError] = useState<string | undefined>();
  const signInWithAppleEnabled = useFeatureFlag('appleSignInOnWebEnabled');
  const useGoogleLoginV3 = useFeatureFlag('googleSignInOAuth2Enabled');
  const { track } = useTracker();

  // Hide Google + Apple login on FB browser only
  const isFacebookOrInstagramFunnel = isFacebookBrowser(navigator.userAgent) || isInstagramBrowser(navigator.userAgent);

  const resetErrors = () => {
    setError(undefined);
    setEmailError(undefined);
  };

  const [getRegisteredEmail, { error: getRegisteredEmailError, data: getRegisteredEmailData }] =
    useGetRegisteredEmailLazyQuery({ fetchPolicy: 'network-only' });

  /**
   * So annoyed with this hack – as far as I can tell LazyQuery doesn't give you a good callback or promised-based way of getting the GraphQLErrors, so
   * so have to use useEffect. In addition, the lazyQuery loading is not set to true when the query is retried, so we have to hack around with our own.
   */
  const [getRegisteredEmailLoading, setGetRegisteredEmailLoading] = useState(false);

  useEffect(() => {
    if (getRegisteredEmailLoading) {
      return;
    }

    if (!isNil(getRegisteredEmailError)) {
      const invalidEmailErrors = getRegisteredEmailError.graphQLErrors.filter(
        (graphQLError) => graphQLError.extensions?.code === ShefErrorCode.INVALID_EMAIL
      );
      if (invalidEmailErrors.length > 0) {
        setError({ type: AuthType.EMAIL, errorMessage: invalidEmailErrors[0].message });
      } else {
        setError({
          type: AuthType.EMAIL,
          errorMessage: 'There was an issue processing your request. Please try again.',
        });
      }
    } else if (getRegisteredEmailData) {
      const registeredEmail = getRegisteredEmailData.getRegisteredEmail;
      const userIsRegistered = !isNil(registeredEmail);

      onEmailContinueSuccess(registeredEmail ?? email, userIsRegistered);
    }
  }, [getRegisteredEmailError?.message, getRegisteredEmailData?.getRegisteredEmail, getRegisteredEmailLoading]);

  const handleEmailContinue = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (getRegisteredEmailLoading) {
      return;
    }

    resetErrors();

    const emailCheck = checkEmail(email);
    if (isLeft(emailCheck)) {
      setEmailError(emailCheck.left);
    } else {
      track(ClientEvents.LOGIN_MODAL_EMAIL_ENTERED, { signUpEntryPoint });
      getRegisteredEmail({ variables: { email: email.trim() } }).then(() => setGetRegisteredEmailLoading(false));
      setGetRegisteredEmailLoading(true);
    }
  };

  // Theoretically this shouldn't be needed – not sure why enter isn't handling form submission like normal
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleEmailContinue();
    }
  };

  const displayError = (type: AuthType, providerName: string) => (error: Error) => {
    const message = isAllowListedError(error)
      ? error.message
      : `There was an issue logging in with ${providerName}. Please try again.`;
    setError({ type, errorMessage: message });
  };
  const GoogleLoginComponent = useGoogleLoginV3 ? GoogleLoginV3 : GoogleLoginV2;

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col'>
        <div className='flex flex-row gap-2'>
          {!isFacebookOrInstagramFunnel && (
            <div className='flex w-full flex-col gap-2'>
              <GoogleLoginComponent
                onMissingFields={onOAuthMissingFields}
                onAttempt={resetErrors}
                onSuccess={onOAuthSuccess}
                onError={displayError(AuthType.GOOGLE, 'Google')}
                requireZipCode={requireZipCode}
                signUpEntryPoint={signUpEntryPoint}
              />
            </div>
          )}
          {!isFacebookOrInstagramFunnel && (
            <div className='flex w-full flex-col gap-2'>
              <FacebookLoginV2
                onMissingFields={onOAuthMissingFields}
                onAttempt={resetErrors}
                onSuccess={onOAuthSuccess}
                onError={displayError(AuthType.FACEBOOK, 'Facebook')}
                requireZipCode={requireZipCode}
                signUpEntryPoint={signUpEntryPoint}
              />
            </div>
          )}
          {signInWithAppleEnabled ? (
            <div className='flex w-full flex-col gap-2'>
              <AppleLogin
                onMissingFields={onOAuthMissingFields}
                onAttempt={resetErrors}
                onSuccess={onOAuthSuccess}
                onError={displayError(AuthType.APPLE, 'Apple')}
                requireZipCode={requireZipCode}
                signUpEntryPoint={signUpEntryPoint}
              />
            </div>
          ) : null}
        </div>
        {error ? <ErrorMessageDisplay errorMessage={error.errorMessage} /> : null}
      </div>

      <div className='text-center text-base font-semibold uppercase leading-5 text-eggplant-500'> Or </div>
      <form
        onSubmit={(event) => {
          handleEmailContinue(event);
        }}
        onKeyDown={handleKeyDown}
        ref={formRef}>
        <fieldset className='flex flex-col items-center gap-4' disabled={getRegisteredEmailLoading}>
          <div className='w-full text-eggplant-200'>
            <LabeledTextInput
              label='Email'
              name='email'
              value={email}
              onChange={onEmailChange}
              errorMessage={emailError}
              data-cy='login-entry-email'
            />
          </div>
          {error && error.type === AuthType.EMAIL ? <ErrorMessageDisplay errorMessage={error.errorMessage} /> : null}
          <Button
            buttonType={ButtonType.Primary}
            disabled={getRegisteredEmailLoading}
            iconName='arrowRight'
            renderFullWidth
            data-cy='login-entry-continue'>
            {continueCTA}
          </Button>
          {formCloseButton}
        </fieldset>
      </form>
    </div>
  );
};
