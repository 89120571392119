import { DateTime } from 'luxon';

export const DEFAULT_PLATFORM_PERCENT_AS_DECIMAL = 0.25;
export const DEFAULT_PLATFORM_PERCENT = DEFAULT_PLATFORM_PERCENT_AS_DECIMAL * 100;
export const MIN_PLATFORM_PERCENT = 0;
export const MAX_PLATFORM_PERCENT = 100;
export const MINOR_TO_MAJOR = 100;

export enum TimeZone {
  LosAngeles = 'America/Los_Angeles',
  NewYork = 'America/New_York',
  Chicago = 'America/Chicago',
  UTC = 'UTC',
}

// Keep in sync with shef-api/src/order-totals/bll/calculateOrderTotals.ts L27
export const SERVICE_FEE_RATE = 0.0925;

export function getDepositCostByUnitsAndServings(numUnits: number, numServings: number): number {
  const priceTable: { [key: number]: number[] } = {
    1: [0, 0, 0, 1200, 1400, 1400], // Servings = 1, only valid for Mains >= 4
    2: [900, 1200, 1600, 1900, 1900, 1900], // Servings = 2
    3: [1400, 1400, 1900, 1900, 2400, 2400], // Servings = 3
    4: [1400, 1900, 1900, 2400, 2400, 3900], // Servings = 4
    5: [1400, 1900, 2400, 2400, 3900, 4400], // Servings = 5
    6: [1400, 1900, 2400, 3900, 4400, 4400], // Servings = 6
  };

  if (numServings < 1 || numServings > 6 || numUnits < 1 || numUnits > 6) {
    throw new Error('Invalid numMains or numServings. Must be in range: Servings (1-6), Mains (1-6).');
  }

  if (numServings === 1 && numUnits < 4) {
    throw new Error('For 1 serving, numMains must be 4 or greater.');
  }

  return priceTable[numServings][numUnits - 1];
}

export enum DeliveryType {
  DoorDash = 'DOORDASH',
  Internal = 'INTERNAL',
  Unknown = 'UNKNOWN',
  Lalamove = 'LALAMOVE',
  Pickup = 'PICKUP',
  Fulflld = 'FULFLLD',
  ShippedByMail = 'SHIPPED_BY_MAIL', // Deprecated 12-9-2022. Leaving to allow orders to still be seen in receipts
  Nash = 'NASH',
  DirectViaShefs = 'DIRECT_VIA_SHEFS',
  DirectViaNash = 'DIRECT_VIA_NASH', // This is because Nash delivery does not operate the same in pick-up only regions.
  // Zippy = 'ZIPPY', - Deprecated on 8/3/22
  // Gofor = 'GOFOR', - Deprecated on 8/3/22
  // PARTNER_NAMED_DROPOFF = 'PARTNER_DROPOFF', // We have a delivery partner named DROPOFF. SO confusing. Deprecated in January/2022. Used for NYC
  // FIRST = 'FIRST', -- Deprecated 6/22 - in Seattle
}

// Find more information on these values here:
// https://www.notion.so/Shef-fulfilled-Delivery-in-Pick-Up-Regions-bbbb60fb930f4cebb4c1c80182edb9cc
export enum FulfillmentOption {
  Pickup = 'PICKUP',
  DirectViaShefs = 'DIRECT_VIA_SHEFS',
  DirectViaNash = 'DIRECT_VIA_NASH',
}

export enum NotificationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
}

export enum NotificationStatus {
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
}

export enum DietaryTag {
  ContainsNuts = 'containsNuts',
  DairyFree = 'dairyFree',
  GlutenFree = 'glutenFree',
  Halal = 'halal',
  LowFodmap = 'lowFodmap',
  Organic = 'organic',
  Paleo = 'paleo',
  Vegan = 'vegan',
  Vegetarian = 'vegetarian',
  Whole30 = 'whole30',
  Kosher = 'kosher',
}

export type DietaryRestriction = Extract<DietaryTag, DietaryTag.GlutenFree | DietaryTag.DairyFree>;

export enum PaymentCaptureType {
  /**
   * Collect payment while the user is offline, at some time in the future. In
   * order to do this, the user's credit card information is stored by stripe.
   * Capturing payments while the user is offline does not guarantee that a
   * payment can actually be completed.
   */

  // NOTE THIS WAS DEPRECATED WHEN WE MOVED TO GROUP ORDERS around dec 2022
  STRIPE_OFFLINE_CHARGE = 'STRIPE_OFFLINE_CHARGE', // DEPRECATED

  /**
   * Create a payment intent immediately, essentially placing a hold for a
   * specific dollar amount on the consumer's card. The payment intent will
   * expire in 7 days, unless the payment is explicitly  captured, transfering
   * the money to our stripe account.
   */
  STRIPE_IMMEDIATE_PAYMENT_INTENT = 'STRIPE_IMMEDIATE_PAYMENT_INTENT',
}

export const ORDER_STATUS_TO_REVERSE_SHEF_PAYMENT: readonly string[] = [
  'SHEF',
  'USER_CANCELLED',
  'SUBSCRIPTION_CANCELED',
];

export const Time = {
  ONE_YEAR_IN_MS: 365 * 24 * 60 * 60 * 1000,
  ONE_WEEK_IN_MS: 7 * 24 * 60 * 60 * 1000,
  ONE_DAY_IN_MS: 24 * 60 * 60 * 1000,
  ONE_HOUR_IN_MS: 60 * 60 * 1000,
  ONE_MINUTE_IN_MS: 60 * 1000,
  ONE_SECOND_IN_MS: 1000,
};

export const noOp = (): void => {
  /* noOp */
};

export const POST_REVIEW_PROMO_TIME_LIMIT_HOURS = 2;

export const MIN_DATE = DateTime.utc(1970);
export const MAX_DATE = DateTime.utc(2999);

export const MAX_STARS = 5;

export enum ColorTheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export const HUBLESS_DELIVERY_MAX_DISTANCE = 35; // miles

export const DELIVERY_IN_PICKUP_REGION_ORDER_MINIMUM = 2500;

export const SAME_DAY_PROMO_SUFFIX = 'same-day-free-delivery';

export const DUMMY_BARCODE_VALUE = 'Barcode Coming Soon!';

export const DEFAULT_REFERRAL_VALUE = 3000;
export const DEFAULT_REFERRER_VALUE = DEFAULT_REFERRAL_VALUE * 2;
export const DEFAULT_REFERRAL_USES_PER_USER = 2;

export const ZIP_CODE_REGEX = /^\d{5}$/;

export const UNAVAILABLE_IMAGE_URL =
  'https://shef-general.s3-us-west-1.amazonaws.com/static/media/img_unavailable.012cee8d.jpg';
export const EMAIL_ANYWHERE_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const isUserInMealPlanRegion = (userRegion: number): boolean => [1, 2, 5, 6].includes(userRegion);

export const shouldSkipDoorToDoorPopUpRegion = (userRegion: number): boolean => [1, 2, 5, 6, 8].includes(userRegion);

export const REGION_ID_TO_REGION_PROMO_CODE: Record<number, string> = {
  1: 'meal_plans_30_bay',
  2: 'meal_plans_30_ny',
  5: 'meal_plans_30_chicago',
  6: 'meal_plans_30_seattle',
};

export const MEAL_PLAN_DEFAULT_SIDE_PRICE = 300;
export const FLEXIBLE_SIDES_DEFAULT_STAPLE_PRICE = 300;
export const FLEXIBLE_SIDES_DEFAULT_SIDE_PRICE = 600;
export const MAXIMUM_MEAL_PLAN_SERVINGS = 6;

export const TASTY_SHEFS: { [key: string]: boolean } = {
  'madhuben-p': true,
  'sonal-g-31': true,
  'kelly-b-39': true,
  'anna-h-45': true,
  'beliza-p': true,
  'laurene-f-1': true,
  'mitsue-g': true,
  'yoori-l': true,
  'brandon-t-7': true,
  'hung-h-1': true,
  'win-c-2': true,
  'chrystelle-s': true,
  'cindy-t-11': true,
  'heriberto-f-1': true,
  'qiaoying-g': true,
  'zoe-h-2': true,
  'noah-g-14': true,
  'andrew-e-14': true,
  'ana-w-4': true,
  'robert-m-86': true,
  'woong-c-1': true,
  'zhidu-l-1': true,
  'alissa-h-3': true,
  'chyanne-c': true,
  'skye-h': true,
  'skye-h-1': true,
  'itay-s': true,
  'roy-y-4': true,
  'justin-b-49': true,
  'careem-g': true,
  'kevin-r-34': true,
  'kwesi-r': true,
  'yukako-k-2': true,
  'nicholas-b-21': true,
  'ayah-a-4': true,
  'arthur-c-9': true,
  'maen-r-1': true,
};

export const MEAL_PLAN_SHEFS_TO_DEPRIORITIZE: { [key: string]: boolean } = {
  'sukhdeep-k-2': true,
  'deepti-g-1': true,
  'prachi-p-24': true,
  'joyal-g': true,
};

export const MEAL_PLAN_NY_PLATE_SHEFS: { [key: string]: number } = {};

export const MEAL_PLAN_SHEF_IDS: readonly string[] = ['meal-plan-w', 'meal-plan-w-1', 'meal-plan-w-2', 'meal-plan-w-3'];

export const HST_TO_UTC_OFFSET = 10;

export const MAINS_PLUS_SIDES_MP_MAIN_FOOD_ITEM_IDS = new Set<number>([
  283, 13131, 5066, 67, 582, 169943, 101, 187, 153, 102, 60, 20069, 63, 305, 19586, 138163, 237909, 230409, 233423,
  144103, 249571, 249572, 249568, 2362, 47673, 2357, 2354, 2453, 2451, 3631, 3632, 2353, 2420, 2355, 108638, 131742,
  169327, 137831, 169321, 103337, 108628, 249679, 244297, 254506, 244180, 244313, 251462, 244288, 228244, 228199,
  228055, 249334, 228070, 228203, 228061, 228063, 228073, 228176, 228048, 228068, 240949, 240942, 228198, 62086, 45945,
  62085, 45823, 45811, 45952, 64441, 46186, 148256, 228197, 204332, 149493, 152361, 151107, 159013, 148071, 149199,
  154197, 149407, 153379, 149193, 148551, 152088, 149600, 225986, 227229, 225394, 230579, 226498, 250317, 234260,
  229799, 219392, 226022, 237594, 226502, 246546, 222047, 236264, 147008, 150157, 146005, 147005, 147037, 153978,
  146999, 146991, 153969, 150147, 169257, 230769, 1531, 1535, 7940, 63973, 35116, 2089, 1530, 1534, 137948, 2091, 7937,
  1532, 152938, 189716, 35926, 188489, 141858, 146057, 241521, 54546, 146073, 82622, 35787, 11045, 25932, 11113, 25404,
  86180, 10999, 21596, 10980, 19729, 221743, 11062, 17228, 26970, 103915, 11061, 11103, 245398, 251218, 246275, 245319,
  246272, 246279, 245231, 245105, 245797, 119461, 125291, 112944, 133279, 123522, 105356, 131419, 125399, 131043,
  119451, 125395, 106058, 144027, 105358, 105357, 131046, 105805, 135631, 164667, 132569, 63041, 62972, 15329, 26911,
  63019, 14813, 15281, 20859, 239798, 239364, 244305, 245408, 249826, 247655, 244301, 239368, 239369, 237799, 245421,
  237850, 9724, 10058, 233725, 233745, 233720, 233739, 233754, 233708, 233733, 127586, 123553, 131503, 123555, 150295,
  137591, 207024, 17970, 25762, 18777, 15213, 28379, 32194, 26071, 88729, 26062, 167896, 26064, 135387, 126099, 133875,
  123959, 131819, 121954, 122695, 133737, 116455, 127798, 133877, 118080, 113893, 133881, 133873, 138664, 116441,
  116449, 116475, 116477, 113821, 250287, 193768, 128641, 134777, 122212, 122211, 119208, 136417, 121087, 127042,
  121094, 126143, 131697, 126139, 126121, 126125, 130800, 214428, 125531, 131601, 131602, 126006, 126396, 125853,
  125532, 130479, 128595, 125858, 132413, 125857, 127201, 61772, 61764, 190338, 62190, 60033, 73949, 61769, 73953, 2886,
  2889, 2885, 53487, 53496, 35201, 128533, 2893, 128545, 3651, 128543, 148890, 148327, 148599, 148605, 148885, 148604,
  252149, 151163, 242029, 148325, 253797, 146299, 145628, 2398, 2384, 2380, 111859, 2376, 244337, 16936, 153899, 136854,
  41397, 150609, 8143, 151397, 8140, 136853, 133443, 8145, 150611, 8142, 8088, 8146, 240994, 241001, 241005, 8083, 8084,
  8085, 150617, 41393, 139187, 113846, 112889, 10046, 9722, 8197, 8198, 10054, 9723, 8200, 10055, 189619, 203725,
  191324, 189620, 31925, 208836, 172965, 172157, 171963, 172733, 172966, 194451, 109305, 109311, 109306, 120613, 109248,
  118115, 108120, 109312, 108119, 232676, 256706, 233084, 249422, 231939, 231943, 245528, 239715, 231953, 235548,
  251414, 122246, 254798, 254330, 176167, 171693, 174229, 174234, 176168, 174232, 171956, 174233, 174230, 171694,
  170926, 176595, 99328, 136091, 100238, 100244, 99329, 99327, 133335, 163975, 61018, 33570, 74670, 151012, 124585,
  125146, 168314, 225914, 191309, 124598, 248943, 124597, 125145, 245178, 144700, 168313, 131991, 191310, 151011,
  171395, 158145, 256623, 3197, 86611, 45804, 8665, 2647, 26168, 193292, 2426, 8457, 26089, 26339, 247304, 14092,
  208970, 145649, 2350, 256375, 124628, 256374, 45805, 85197, 2952, 3200, 142349, 8371, 2395, 2649, 254598, 224972,
  248322, 3196, 120524, 254601, 14380, 256463, 13303, 4295, 3198, 2425, 3201, 37970, 145123, 145117, 37928, 37949,
  145102, 37954, 22122, 19602, 20249, 68013, 20238, 73491, 27513, 25606, 21875, 20438, 22230, 22217, 22231, 226334,
  22223, 57699, 22062, 22232, 22226, 84084, 178898, 214494, 241784, 216287, 213180, 254126, 230519, 247917, 247907,
  226111, 221916, 226112, 213174, 213177, 216286, 245744, 245814, 255481, 255483, 245720, 255491, 235162, 245770,
  245693, 235188, 235178, 245784, 241855, 255488, 245796, 255498, 255501, 255495, 255493, 255503, 255506, 255641,
  255508, 245818, 60841, 144917, 180846, 243326, 243322, 60801, 60177, 229896, 144911, 162140, 60751, 229885, 166637,
  180898, 76058, 60383, 82343, 180900, 180915, 150886, 150709, 150898, 131013, 131014, 150710, 154774, 150785, 151345,
  150893, 150786, 150776, 150888, 151346, 250563, 80325, 76168, 76181, 76167, 262439, 80327, 81483, 80339, 146656,
  144744, 144936, 144727, 144937, 146735, 144728, 144736, 148545, 144739, 144743, 148494, 144742, 144735, 144833,
  101159, 154574, 89217, 97113, 223318, 156907, 120509, 38470, 38539, 38557, 38520, 205717, 37094, 37086, 37082, 205722,
  153378, 237663, 37088, 203922, 203703, 203716, 230522, 203705, 64790, 181628, 209652, 123905, 207694, 181593, 242265,
  123129, 152532, 64877, 64789, 195386, 149204, 149897, 63614, 256803, 63616, 154566, 91234, 64807, 125124, 195376,
  84155, 221040, 199035, 161090, 224522, 203303, 215168, 203304, 160468, 250882, 249672, 177151, 226321, 9231, 9619,
  9626, 216072, 219708, 201508, 184301, 183977, 201526, 181891, 207458, 184275, 220774, 1735, 10403, 1013, 982, 964,
  981, 152331, 152445, 152353, 153763, 167493, 166952, 216664, 81365, 163023, 163251, 76749, 162277, 74256, 76777,
  128335, 131226, 163211, 162714, 162278, 150829, 140119, 162711, 163212, 46238, 46246, 128706, 46237, 88760, 122853,
  122861, 44943, 122854, 122862, 46241, 46236, 128704, 78412, 83853, 77881, 77832, 83855, 83856, 77830, 83854, 112800,
  77831, 174665, 77827, 77828, 77829, 207364, 224912, 228082, 202626, 206930, 224954, 203884, 213049, 197132, 203845,
  230082, 219160, 197140, 213070, 206976, 201940, 224680, 209478, 203217, 206998, 216900, 216727, 251389, 206050,
  225198, 180471, 109256, 138401, 109230, 107008, 109226, 138402, 109229, 109255, 109266, 138403, 138374, 138373,
  192641, 170545, 255669, 222254, 45010, 45018, 39261, 134220, 165233, 157638, 33574, 33578, 22756, 22407, 32355, 25959,
  22620, 138189, 160949, 228849, 171221, 22473, 245594, 246084, 247979, 248016, 250877, 252390, 245968, 247911, 246191,
  246579, 247162, 251054, 249168, 251311, 247163, 246230, 246097, 246072, 247616, 28546, 35843, 130257, 38307, 27041,
  27357, 27933, 27306, 32561, 55533, 79365, 146930, 145474, 27220, 38309, 38301, 145476, 35842, 239790, 20714, 239796,
  20723, 224204, 20703, 20709, 156668, 167687, 14978, 202540, 149747, 149744, 220234, 189076, 39956, 28176, 29741,
  48615, 28518, 34128, 16773, 30363, 16542, 134849, 106437, 49843, 49845, 44125, 44173, 98941, 102868, 204020, 204030,
  237046, 34166, 116652, 35867, 129531, 34154, 34836, 36409, 36277, 34565, 51629, 40276, 34131, 35136, 202194, 127268,
  55908, 38486, 29270, 29388, 29406, 105988, 105987, 29438, 29451, 51016, 29413, 29508, 29274, 237666, 244359, 244700,
  244362, 237676, 237673, 244176, 237669, 244695, 235733, 251061, 254734, 246226, 251060, 244175, 244173, 250218,
  252144, 248928, 244151, 128052, 172729, 80349, 128051, 168623, 168624, 187715, 153995, 152519, 153996, 154979, 153889,
  152520, 225479, 186759, 191830, 195158, 195152, 199068, 202782, 243606, 213892, 204384, 185019, 185020, 186802,
  227772, 244660, 193526, 250645, 203804, 187875, 185845, 244277, 217371, 217372, 213915, 224270, 155583, 155577,
  155473, 155454, 246999, 155587, 246782, 250503, 246776, 236985, 240550, 243328, 240897, 243320, 235948, 254628,
  241436, 250257, 243662, 235935, 236867, 248852, 244989, 245070, 246781, 239304, 245068, 248599, 248598, 128052, 53033,
  29253, 53065, 29173, 53066, 53029, 29178, 53056, 240831, 237064, 238224, 241945, 252400, 241918, 239997, 238230,
  240838, 255078, 255080, 238383, 252411, 238378, 238370, 236577, 140475, 140487, 152509, 140472, 140468, 148270,
  147888, 140473, 152517, 148276, 144044, 144045, 140467, 148273, 144041, 144141, 140478, 144012, 140474, 63018, 63279,
  82095, 99528, 180604, 74131, 63056, 99532, 68241, 73780, 63095, 68250, 143200, 143177, 143191, 143201, 143196, 143181,
  143354, 143351, 151985, 143179, 144698, 151981, 159072, 143347, 143197, 159087, 159082, 159089, 206664, 153267,
  187272, 186857, 252561, 22575, 186866, 186852, 186849, 186983, 186864, 153042, 154923, 153035, 153039, 151613, 154958,
  209660, 188977, 150957, 170413, 170430, 165599, 170405, 65275, 65265, 24084, 226143, 23531, 59908, 226134, 26177,
  222532, 24332, 238136, 23579, 232455, 215216, 213865, 222520, 215211, 151203, 148399, 171502, 259667, 251951, 149338,
  154262, 169553, 169912, 232321, 171887, 194323, 232323, 173640, 173639, 170142, 169910, 169909, 170141, 174324,
  174323, 184968, 194307, 194330, 187568, 181567, 260625, 218109, 220077, 234873, 216471, 215100, 215105, 220083,
  216474, 164452, 175353, 207648, 170924, 168302, 238324, 170867, 178815, 174257, 204000, 170924, 167595, 170892,
  178043, 167598, 25850, 25848, 31591, 149715, 35284, 154966, 154967, 178427, 154973, 178194, 200777, 237262, 175921,
  175891, 237263, 214510, 125459, 144066, 157315, 238295, 182594, 208518, 65787, 163891, 72238, 240049, 121071, 161010,
  66556, 229491, 242909, 121085, 201588, 160993, 213283, 225701, 245472, 121086, 215621, 66555, 19323, 21384, 21381,
  205812, 19319, 205811, 21833, 21834, 205823, 21835, 187909, 151835, 181885, 204448, 175379, 183771, 215670, 249127,
  179702, 251863, 147516, 204356, 147518, 157538, 153208, 153201, 251665, 204352, 148463, 148459, 157541, 252243,
  191198, 101678, 43957, 25768, 55819, 29544, 64995, 17438, 16083, 17263, 171481, 72714, 111149, 72713, 17268, 17251,
  72593, 28653, 17250, 21089, 164469, 164459, 164463, 164482, 164476, 162035, 164471, 164477, 164453, 164468, 245244,
  245753, 245094, 255091, 241622, 248096, 254418, 249201, 245193, 239060, 241617, 254417, 240419, 239058, 245433,
  247923, 246852, 233496, 235898, 233483, 234172, 232423, 238706, 234357, 193923, 241214, 223900, 236014, 237215,
  241771, 230534, 241330, 16361, 45019, 44186, 45025, 218108, 197786, 197785, 16398, 92548, 17405, 23260, 17404, 17407,
  85346, 61660, 61094, 65914, 81619, 114769, 86260, 80461, 114781, 81821, 83013, 256078, 94739, 84174, 80462, 114777,
  145093, 149515, 80465, 80466, 81457, 80458, 80464, 84176, 180711, 177616, 176527, 205843, 226584, 87377, 36595, 86271,
  37231, 36257, 36151, 36602, 36154, 250226, 36253, 134501, 149987, 216258, 133328, 192816, 224542, 133460, 133372,
  145564, 133377, 155895, 133337, 133367, 25514, 236868, 25509, 147967, 165209, 46359, 144575, 39799, 167557, 59704,
  44254, 40521, 39883, 39878, 39885, 39859, 82926, 39967, 39843, 61075, 149150, 44570, 39775, 92497, 41262, 226529,
  251926, 256161, 216204, 221230, 198394, 254223, 205522, 236391, 254358, 230968, 228110, 230631, 251924, 226530,
  239561, 231318, 256414, 227151, 227149, 229059, 231760, 226935, 230785, 232425, 227145, 226941, 232376, 229064,
  226931, 226949, 227180, 227872, 222519, 237243, 234106, 234112, 237244, 222527, 219850, 222539, 222695, 222704,
  254936, 255358, 253656, 256011, 257235, 261502, 256281, 256295, 255788, 260775, 255580, 257297, 256357, 25508, 25517,
  25516, 79653, 62901, 26103, 187917, 239785, 257472, 187915, 186652, 231436, 231441, 233480, 231465, 231461, 231459,
  183529, 182213, 182325, 182704, 63193, 63670, 196806, 60373, 172296, 60042, 135928, 239475, 72272, 61724, 180785,
  197510, 207052, 22329, 19222, 146547, 25340, 15887, 27444, 71851, 19907, 88797, 124445, 77353, 217496, 209977, 215872,
  219861, 253727, 221083, 216137, 251073, 216433, 216429, 253725, 195730, 193734, 200912, 195932, 238037, 193627,
  200902, 193978, 193628, 193718, 126371, 126361, 126258, 148901, 126365, 123113, 262071, 262073, 252516, 260057, 29133,
  33101, 54960, 28211, 33287, 30212, 30203, 54957, 28224, 28221, 28225, 29247, 82853, 28226, 262520, 54959, 262518,
  255475, 255472, 197852, 261851, 30136, 68553, 29542, 32687, 252205, 135691, 25692, 101683, 140253, 203222, 25771,
  90699, 169295, 179457, 60035, 60374, 72271, 262077, 226864, 60196, 18076, 23497, 171417, 232773, 245033, 231743,
  241254, 193927, 223897, 204588, 190814, 186120, 259526, 259524, 25512, 264293, 264332, 131135, 233548, 144110, 231136,
  232631, 232629, 144094, 237444, 230506, 144111, 232398, 230408, 144098, 237447, 144108, 144105, 152145, 259747,
  152148, 259751, 259755, 47690, 2452, 261529, 254511, 157215, 151631, 149915, 261459, 173885, 227539, 225241, 251668,
  263114, 260938, 247694, 234870, 265913, 241910, 230085, 225948, 261023, 262280, 266150, 260297, 266151, 225125,
  225979, 226614, 227470, 225950, 262282, 253903, 231508, 226038, 227220, 150161, 150167, 147027, 127271, 264623,
  264571, 260342, 265915, 82335, 82336, 103344, 82713, 82714, 84083, 179095, 256528, 256536, 256354, 261797, 258815,
  260143, 260522, 254891, 265701, 260849, 264205, 256288, 35790, 35930, 36580, 46780, 65036, 42735, 35929, 188490,
  39451, 72899, 39449, 54533, 231745, 232454, 51392, 239736, 79076, 160557, 246280, 247587, 266364, 245059, 245228,
  245101, 154615, 150775, 150778, 154805, 148008, 144738, 122175, 258347, 253136, 119462, 140943, 140937, 141579,
  179141, 133273, 112943, 119456, 157550, 179176, 63023, 14814, 63027, 62957, 15253, 63031, 63034, 15144, 177252,
  203921, 209651, 260910, 174191, 183990, 174177, 61936, 135300, 123963, 133879, 116451, 144127, 193749, 193804, 114239,
  264169, 265331, 179995, 193762, 225707, 179993, 142480, 193782, 259428, 167487, 198416, 198414, 157401, 162588,
  152300, 225879, 147971, 213713, 132417, 173106, 126709, 73948, 128557, 151157, 148597, 241019, 241017, 2401, 193665,
  2403, 244120, 231694, 244121, 231696, 193162, 244333, 150615, 231706, 244110, 244135, 231701, 231710, 244122, 258820,
  262929, 261800, 258822, 259594, 263681, 261501, 35790, 35930, 36580, 46780, 65036, 42735, 35929, 188490, 39451, 72899,
  39449, 54533, 231745, 232454, 51392, 239736, 79076, 160557, 246280, 247587, 266364, 245059, 245228, 245101, 154615,
  150775, 150778, 154805, 148008, 144738, 122175, 258347, 253136, 119462, 140943, 140937, 141579, 179141, 133273,
  112943, 119456, 157550, 179176, 63023, 14814, 63027, 62957, 15253, 63031, 63034, 15144, 177252, 203921, 209651,
  260910, 174191, 183990, 174177, 61936, 135300, 123963, 133879, 116451, 144127, 193749, 193804, 114239, 264169, 265331,
  179995, 193762, 225707, 179993, 142480, 193782, 259428, 167487, 198416, 198414, 157401, 162588, 152300, 225879,
  147971, 213713, 132417, 173106, 126709, 73948, 128557, 151157, 148597, 241019, 241017, 2401, 193665, 2403, 244120,
  231694, 244121, 231696, 193162, 244333, 150615, 231706, 244110, 244135, 231701, 231710, 244122, 161261, 161262,
  161321, 161250, 257708, 184508, 234575, 196235, 181270, 196236, 234578, 153754, 154402, 153819, 180015, 153739,
  154401, 153753, 23074, 24176, 21147, 21149, 25102, 134673, 21144, 250767, 244961, 246977, 247904, 249880, 245299,
  245922, 247270, 244969, 245344, 251945, 247796, 246236, 254731, 245921, 244960, 245721, 250107, 254568, 260073,
  250102, 249853, 246238, 245768, 247691, 246227, 246147, 245538, 257686, 244947, 248158, 254446, 252787, 246645,
  246926, 246005, 248154, 250034, 251143, 52817, 50513, 243359, 48230, 55546, 50514, 73031, 213545, 48229, 46695, 48228,
  114944, 9508, 9504, 9503, 9506, 9505, 9396, 9509, 262665, 262717, 155856, 190284, 219259, 194551, 194554, 194321,
  194553, 230060, 194552, 229812, 194296, 194322, 222070, 194381, 229815, 238293, 194359, 35715, 148014, 136343, 35717,
  50619, 34300, 34297, 47754, 29822, 32103, 35944, 30380, 238948, 239414, 30378, 239620, 35947, 247702, 252754, 252721,
  256085, 255768, 251026, 250995, 252698, 252662, 258681, 252700, 255270, 252693, 256084, 258680, 250985, 258683,
  258682, 264208, 130159, 131487, 239878, 136909, 128646, 247619, 128693, 128727, 142453, 127576, 250828, 130251,
  134382, 133225, 2665, 10385, 2675, 2681, 10316, 2677, 12397, 2673, 2674, 2682, 12394, 12398, 2671, 2672, 104152,
  104163, 117683, 222450, 256718, 230771, 224278, 224435, 230850, 234266, 251023, 253324, 253333, 255794, 261687,
  251022, 261688, 253307, 250604, 244196, 243033, 254941, 243573, 261063, 266080, 260747, 256457, 262202, 244103,
  260215, 241943, 267436, 261747, 251662, 256338, 246334, 245863, 257441, 266099, 244191, 246362, 219638, 167304,
  205088, 197366, 221778, 197333, 264653, 222650, 219627, 214450, 203502, 264918, 219629, 167316, 219631, 199070,
  219632, 260729, 240936, 265130, 260093, 260089, 262557, 259016, 261199, 242059, 261207, 261197, 265131, 233534,
  252610, 239758, 248167, 262561, 261213, 265124, 260728, 254071, 254078, 258636, 254074, 254068, 254073, 258630, 11713,
  11715, 11977, 11705, 11546, 91976, 125320, 251407, 8531, 10712, 140745, 140298, 8674, 21908, 141892, 247187, 85032,
  91980, 43996, 91975, 98187, 149709, 102367, 133307, 94388, 3985, 8987, 132466, 13551, 125327, 139855, 142448, 139856,
  142447, 149711, 191840, 142434, 149710, 142433, 191819, 125303, 153593, 118172, 239390, 100720, 102144, 106995,
  134087, 147368, 105128, 104292, 104412, 104413, 205535, 102286, 102143, 100553, 205544, 144630, 105429, 133995,
  137111, 8881, 43997, 6193, 6356, 31161, 6148, 267829, 257958, 134847, 117093, 176367, 234485, 172863, 208410, 19127,
  19129, 224577, 172865, 56871, 208824, 13534, 13529, 224579, 208696, 56884, 14927, 232705, 132465, 102285, 6358,
  140297, 146445, 100694, 94397, 140742, 7222, 9567, 8738, 31136, 6572, 152337, 151863, 252565, 156153, 4414, 4402,
  152338, 167293, 152268, 252564, 152267, 6237, 155884, 151864, 156150, 252711, 228751, 6229, 189500, 8037, 152452,
  4952, 4956, 135497, 9195, 4932, 7120, 96495, 152269, 156154, 165512, 13556, 132553, 165511, 140741, 7910, 8925, 7381,
  125307, 3979, 249080, 251403, 256020, 171728, 31144, 31147, 251408, 97980, 117376, 99835, 248950, 188768, 188763,
  169104, 97642, 31149, 31152, 120153, 251410, 188767, 251409, 251402, 31134, 31169, 31155, 10272, 7956, 141912, 133324,
  8737, 176370, 9543, 7885, 132554, 141891, 3948, 167294, 140746, 15164, 251401, 6192, 125311, 7955, 4585, 4589, 81353,
  119685, 73956, 4599, 73960, 173764, 63292, 4602, 8486, 63291, 190860, 173789, 4586, 4596, 73955, 173774, 63289, 4597,
  9556, 4601, 3766, 81469, 8650, 81354, 8653, 67021, 80528, 80527, 8487, 81467, 205308, 73973, 190876, 73959, 81470,
  12920, 63290, 257513, 231098, 252078, 213957, 231100, 3778, 252082, 257510, 3785, 231971, 20846, 8001, 9243, 17070,
  3779, 4598, 12178, 228685, 38355, 9242, 8489, 4587, 3768, 229033, 231089, 9541, 10048, 231102, 9544, 213910, 9081,
  231094, 48344, 243755, 265205, 228897, 255901, 227671, 231629, 6094, 232862, 233112, 233108, 228693, 259190, 232003,
  233211, 228034, 17069, 228893, 229796, 230775, 242032, 254114, 191488, 256192, 255610, 255611, 232282, 233114, 231965,
  232283, 5711, 4605, 155213, 252058, 262692, 266480, 248778, 248775, 227009, 232861, 231972, 132879, 249499, 167755,
  126375, 237717, 6095, 243225, 221254, 230775, 233109, 229319, 231964, 243754, 6571, 125843, 48347, 229027, 243752,
  252056, 248749, 81665, 235700, 231971, 100719, 115312, 132880, 265753, 153561, 153559, 100711, 178487, 152239, 235700,
  266479, 233116, 153581, 153249, 219969, 191488, 73974, 235738, 172857, 27329, 196017, 196014, 252654, 235742, 213699,
  185786, 185799, 235735, 202549, 195997, 242279, 196773, 252221, 227478, 215534, 215537, 196020, 252219, 236546,
  195994, 245555, 236463, 205803, 198526, 252216, 198534, 185769, 216559, 205538, 6195, 3784, 14932, 6570, 7953, 265778,
  255953, 254487, 248127, 260764, 248177, 260765, 261697, 258868, 267025, 258865, 254508, 258862, 259169, 258869,
  258863, 258866, 258854, 253601, 254502, 254505, 258864, 253598, 263332, 265778, 255953, 254487, 248127, 260764,
  248177, 260765, 175441, 166869, 172585, 175446, 171179, 167441, 168171, 169117, 220152, 220150, 172038, 215737,
  158467, 153477, 153451, 155123, 158462, 155133, 270676, 270664, 218412, 270681, 218390, 270680, 270663, 218383,
  270674, 270670, 253560, 270678, 164331, 164335, 189646, 233437, 241587, 169384, 237033, 179523, 169757, 180369,
  232122, 164836, 164362, 180373, 163621, 164103, 162745, 237036, 183437, 162925, 183855, 164923, 164695, 247175,
  258257, 247176, 253808, 247173, 247131, 247121, 19202, 19021, 24418, 95177, 19208, 21289, 18858, 18827, 17060, 14131,
  10526, 57703, 58751, 14134, 14656, 16822, 39867, 29121, 65595, 23102, 9017, 14658, 243730, 241949, 248069, 243563,
  8540, 8882, 20015, 147561, 8542, 9701, 13045, 542, 793, 558, 124498, 221917, 225313, 124474, 124503, 124579, 215312,
  190868, 124487, 124493, 171316, 182126, 182479, 171313, 171993, 181128, 188497, 171315, 171314, 172011, 181127,
  181988, 6865, 8399, 6950, 32593, 32592, 101289, 6948, 6946, 6842, 258102, 257548, 259816, 259179, 257478, 83125,
  83089, 75860, 56007, 51175, 56011, 56014, 39160, 196436, 206022, 183222, 215736, 183209, 193058, 183223, 103059,
  185053, 103061, 103046, 125533, 125574, 268359, 103049, 103027, 125529, 103012, 170783, 187641, 170787, 172981,
  170796, 175207, 232002, 158153, 159507, 156363, 192422, 156361, 223613, 143083, 271547, 269868, 269879, 269856,
  269848, 271277, 270994, 269857, 271551, 270687, 270162, 269871, 271417, 271549, 270993, 269853, 269854, 271553,
  271383, 271402, 271525, 271401, 271399, 271526, 271447, 271316, 271268, 271375, 271374, 271373, 266212, 271331,
  271327, 271329, 271328, 271326, 271333, 270553, 270552, 270557, 270554, 270555, 270559, 270544, 272487, 272457,
  272486, 272491, 272492, 272458, 272484, 267818, 247909, 274540, 274536, 274535, 274534, 274696, 274537, 274438,
  274355, 274440, 274350, 274348, 274344, 267967, 274341, 274700, 274699, 274701, 274726, 274725, 274702, 274547,
  275150, 275151, 275152, 275153, 274939, 274937, 274933, 274346, 277387, 277386, 227686, 240544, 278433, 272573,
  272575, 272346,
]);

export const MEAL_PLAN_30_PROMO_CODES: readonly string[] = [
  'meal_plans_30_bay',
  'meal_plans_30_ny',
  'meal_plans_30_chicago',
  'meal_plans_30_seattle',
];

export const MEAL_PLAN_50_PROMO_CODES: readonly string[] = [
  'meal_plans_50_bay',
  'meal_plans_50_ny',
  'meal_plans_50_chicago',
  'meal_plans_50_seattle',
];

export const MARKETPLACE_AVAILABLE_DELIVERY_DAYS: { [key: number]: number[] } = {
  // Bay Area all days
  1: [1, 2, 3, 4, 5, 6, 7],
  // NY Monday Tuesday Thursday Sunday
  2: [1, 2, 4, 7],
  // Chicago Tuesday Wednesday Thursday Sunday
  5: [2, 3, 4, 7],
  // Seattle Not Saturday
  6: [1, 2, 3, 4, 5, 7],
  // LA Tuesday Wednesday Thursday Friday Sunday
  8: [2, 3, 4, 5, 7],
};

export const MAINS_PLUS_SIDES_MP_DELIVERY_DAYS = {
  // Bay
  1: [1, 3, 5, 7],
  // NY
  2: [1, 2, 4, 7],
  // Chicago
  5: [2, 4, 7],
  // Seattle
  6: [1, 2, 4, 7],
};

export const FLEXIBLE_SIDES_MP_DELIVERY_DAYS = {
  // Bay
  1: [1, 2, 3, 7],
  // NY
  2: [1, 2, 4, 7],
};

export const INDIAN_REGIONS = ['NORTH_INDIAN', 'SOUTH_INDIAN', 'WEST_INDIAN', 'EAST_INDIAN', 'INDO_CHINESE'];

// Commenting out cuisines that aren't available now. Leaving them in for future availability
export const CUISINE_MAPPING = {
  // BAY
  1: {
    indianmp: [
      'INDIAN',
      'PAKISTANI',
      'BANGLADESHI',
      'THAI',
      'VIETNAMESE',
      'CHINESE',
      'JAPANESE',
      'KOREAN',
      'MIDDLE_EASTERN',
      'MEDITERRANEAN',
      'AMERICAN',
      'LATIN_AMERICAN',
      'AFRICAN',
    ],
    nonindian: [
      'AMERICAN',
      'MEDITERRANEAN',
      'LATIN_AMERICAN',
      'INDIAN',
      'CHINESE',
      'JAPANESE',
      'KOREAN',
      'THAI',
      'VIETNAMESE',
      'PAKISTANI',
      'BANGLADESHI',
      'MIDDLE_EASTERN',
      'AFRICAN',
    ],
    NORTH_INDIAN: ['DELHI', 'PUNJABI', 'MUGHLAI'],
    SOUTH_INDIAN: ['ANDHRA', 'KERALA', 'TAMILIAN'],
    WEST_INDIAN: ['MAHARASHTRIAN'],
    EAST_INDIAN: ['BENGALI'],
    INDO_CHINESE: [],
  },
  // NY
  2: {
    indianmp: ['INDIAN', 'AMERICAN', 'JAPANESE', 'KOREAN', 'MIDDLE_EASTERN'],
    nonindian: ['AMERICAN', 'INDIAN', 'JAPANESE', 'KOREAN', 'MIDDLE_EASTERN'],
    NORTH_INDIAN: ['DELHI', 'PUNJABI', 'MUGHLAI'],
    SOUTH_INDIAN: ['ANDHRA', 'KERALA', 'TAMILIAN'],
    WEST_INDIAN: ['MAHARASHTRIAN'],
    EAST_INDIAN: ['BENGALI'],
    INDO_CHINESE: [],
  },

  // CHI
  5: {
    indianmp: [
      'INDIAN',
      'BANGLADESHI',
      'INDONESIAN',
      'THAI',
      'JAPANESE',
      'KOREAN',
      'MIDDLE_EASTERN',
      'MEDITERRANEAN',
      'AMERICAN',
      'LATIN_AMERICAN',
    ],
    nonindian: [
      'AMERICAN',
      'MEDITERRANEAN',
      'LATIN_AMERICAN',
      'INDIAN',
      'JAPANESE',
      'KOREAN',
      'INDONESIAN',
      'THAI',
      'BANGLADESHI',
      'MIDDLE_EASTERN',
    ],
    NORTH_INDIAN: ['PUNJABI', 'RAJASTHANI', 'DELHI'],
    SOUTH_INDIAN: ['TAMILIAN', 'ANDHRA', 'HYDERABADI', 'KERALA', 'MYSORE'],
    WEST_INDIAN: ['GUJARATI', 'MAHARASHTRIAN'],
    EAST_INDIAN: ['BENGALI'],
  },
  // SEA
  6: {
    indianmp: [
      'INDIAN',
      'PAKISTANI',
      'BANGLADESHI',
      'THAI',
      'CHINESE',
      'JAPANESE',
      'KOREAN',
      'MIDDLE_EASTERN',
      'MEDITERRANEAN',
      'AMERICAN',
      'LATIN_AMERICAN',
    ],
    nonindian: [
      'AMERICAN',
      'MEDITERRANEAN',
      'LATIN_AMERICAN',
      'INDIAN',
      'CHINESE',
      'JAPANESE',
      'KOREAN',
      'THAI',
      'PAKISTANI',
      'BANGLADESHI',
      'MIDDLE_EASTERN',
    ],
    NORTH_INDIAN: ['PUNJABI', 'MUGHLAI', 'RAJASTHANI'],
    SOUTH_INDIAN: ['KARNATAKA', 'TAMILIAN'],
    WEST_INDIAN: ['GUJARATI', 'MAHARASHTRIAN'],
    EAST_INDIAN: ['BENGALI'],
    INDO_CHINESE: [],
  },
};

export const MEAL_PLAN_REGIONS_IDS = Object.keys(CUISINE_MAPPING).map((key) => parseInt(key, 10));
export const FLEXIBLE_SIDES_REGION_IDS = [1, 2];
export const REUSABLE_PACKAGING_REGION_IDS = [2];

export const ALL_CUISINES = [
  {
    cuisineFilter: 'AFRICAN',
    rootCuisineFilter: 'AFRICAN',
    title: 'African',
    isSelected: false,
  },
  {
    cuisineFilter: 'AMERICAN',
    rootCuisineFilter: 'AMERICAN',
    title: 'American',
    isSelected: false,
  },
  {
    cuisineFilter: 'ANDHRA',
    rootCuisineFilter: 'SOUTH_INDIAN',
    title: 'Andhra',
    isSelected: false,
  },
  {
    cuisineFilter: 'BANGLADESHI',
    rootCuisineFilter: 'SOUTHEAST_ASIAN',
    title: 'Bangladeshi',
    isSelected: false,
  },
  {
    cuisineFilter: 'BENGALI',
    rootCuisineFilter: 'EAST_INDIAN',
    title: 'Bengali',
    isSelected: false,
  },
  {
    cuisineFilter: 'CHINESE',
    rootCuisineFilter: 'CHINESE',
    title: 'Chinese',
    isSelected: false,
  },
  {
    cuisineFilter: 'DELHI',
    rootCuisineFilter: 'NORTH_INDIAN',
    title: 'Delhi',
    isSelected: false,
  },
  {
    cuisineFilter: 'GUJARATI',
    rootCuisineFilter: 'GUJARATI',
    title: 'Gujarati',
    isSelected: false,
  },
  {
    cuisineFilter: 'HYDERABADI',
    rootCuisineFilter: 'HYDERABADI',
    title: 'Hyderabadi',
    isSelected: false,
  },
  {
    cuisineFilter: 'INDIAN',
    rootCuisineFilter: 'INDIAN',
    title: 'Indian',
    isSelected: false,
  },
  {
    cuisineFilter: 'NORTH_INDIAN',
    rootCuisineFilter: 'INDIAN',
    title: 'North Indian',
    isSelected: false,
  },
  {
    cuisineFilter: 'SOUTH_INDIAN',
    rootCuisineFilter: 'INDIAN',
    title: 'South Indian',
    isSelected: false,
  },
  {
    cuisineFilter: 'WEST_INDIAN',
    rootCuisineFilter: 'INDIAN',
    title: 'West Indian',
    isSelected: false,
  },
  {
    cuisineFilter: 'EAST_INDIAN',
    rootCuisineFilter: 'INDIAN',
    title: 'East Indian',
    isSelected: false,
  },
  {
    cuisineFilter: 'INDONESIAN',
    rootCuisineFilter: 'SOUTHEAST_ASIAN',
    title: 'Indonesian',
    isSelected: false,
  },
  {
    cuisineFilter: 'INDO_CHINESE',
    rootCuisineFilter: 'INDIAN',
    title: 'Indo Chinese',
    isSelected: false,
  },
  {
    cuisineFilter: 'JAPANESE',
    rootCuisineFilter: 'JAPANESE',
    title: 'Japanese',
    isSelected: false,
  },
  {
    cuisineFilter: 'KARNATAKA',
    rootCuisineFilter: 'KARNATAKA',
    title: 'Karnataka',
    isSelected: false,
  },
  {
    cuisineFilter: 'KERALA',
    rootCuisineFilter: 'SOUTH_INDIAN',
    title: 'Kerala',
    isSelected: false,
  },
  {
    cuisineFilter: 'KOREAN',
    rootCuisineFilter: 'KOREAN',
    title: 'Korean',
    isSelected: false,
  },
  {
    cuisineFilter: 'LATIN_AMERICAN',
    rootCuisineFilter: 'LATIN_AMERICAN',
    title: 'Latin American',
    isSelected: false,
  },
  {
    cuisineFilter: 'MAHARASHTRIAN',
    rootCuisineFilter: 'WEST_INDIAN',
    title: 'Maharashtrian',
    isSelected: false,
  },
  {
    cuisineFilter: 'MEDITERRANEAN',
    rootCuisineFilter: 'MEDITERRANEAN',
    title: 'Mediterranean',
    isSelected: false,
  },
  {
    cuisineFilter: 'MIDDLE_EASTERN',
    rootCuisineFilter: 'MIDDLE_EASTERN',
    title: 'Middle Eastern',
    isSelected: false,
  },
  {
    cuisineFilter: 'MUGHLAI',
    rootCuisineFilter: 'NORTH_INDIAN',
    title: 'Mughlai',
    isSelected: false,
  },
  {
    cuisineFilter: 'MYSORE',
    rootCuisineFilter: 'MYSORE',
    title: 'Mysore',
    isSelected: false,
  },
  {
    cuisineFilter: 'PAKISTANI',
    rootCuisineFilter: 'PAKISTANI',
    title: 'Pakistani',
    isSelected: false,
  },
  {
    cuisineFilter: 'PUNJABI',
    rootCuisineFilter: 'NORTH_INDIAN',
    title: 'Punjabi',
    isSelected: false,
  },
  {
    cuisineFilter: 'RAJASTHANI',
    rootCuisineFilter: 'RAJASTHANI',
    title: 'Rajasthani',
    isSelected: false,
  },
  {
    cuisineFilter: 'TAMILIAN',
    rootCuisineFilter: 'SOUTH_INDIAN',
    title: 'Tamilian',
    isSelected: false,
  },
  {
    cuisineFilter: 'THAI',
    rootCuisineFilter: 'SOUTHEAST_ASIAN',
    title: 'Thai',
    isSelected: false,
  },
  {
    cuisineFilter: 'VIETNAMESE',
    rootCuisineFilter: 'SOUTHEAST_ASIAN',
    title: 'Vietnamese',
    isSelected: false,
  },
];

type ByServings = {
  ANY: Set<number>;
  TWO_PLUS: Set<number>;
};

const FLEXIBLE_SIDES_MP_MAIN_FOOD_ITEM_IDS_BY_SERVING: ByServings = {
  ANY: new Set([
    // Food items that are available to all serving sizes
    274344, 274438, 274355, 282496, 282497, 282459, 282461, 282462, 282850, 282515, 282516, 247909, 274725, 248761,
    274701, 275151, 274547, 282469, 282471, 283740, 281777, 251402, 188767, 251404, 169104, 188763, 176367, 283493,
    283494, 283495, 283497, 283250, 283252, 283253, 283255, 63291, 73959, 67021, 81470, 73960, 81469, 73955, 80528,
    190876, 73956, 173764, 81354, 63289, 190860, 81353, 119685, 63290, 173789, 81467, 205308, 231089, 213910, 231102,
    231100, 257513, 231094, 213957, 252078, 266480, 257510, 277387, 278433, 277386, 279816, 280296, 185786, 196020,
    196773, 185799, 195997, 183987, 196017, 205803, 195994, 227478, 252219, 252654, 185769, 226650, 215534, 227476,
    214247, 227474, 224510, 248356, 206228, 206226, 123801, 72802, 128237, 61777, 61778, 60405, 60406, 128238, 61825,
    30982, 30998, 45914, 39262, 32151, 44802, 79241, 42937, 130321, 162752, 193362, 47995, 42597, 41493, 41490, 115534,
    115556, 2682, 2671, 2673, 2665, 2677, 2681, 2672, 2675, 10316, 194451, 172157, 172965, 175205, 194452, 120547,
    95284, 43847, 283166, 283161, 283163, 283170, 283172, 157892, 270664, 270670, 279291, 218412, 46535, 74670, 232602,
    21875, 19602, 25606, 65797, 65804, 128513, 73305, 153528, 141523, 160867, 83249, 130168, 160868, 144911, 270636,
    229896, 76058, 60841, 180915, 268869, 209652, 207694, 64789, 195386, 154566, 149897, 181628, 64877, 221040, 203303,
    161090, 161319, 250882, 283704, 283621, 282987, 161320, 251554, 76749, 140119, 74256, 131226, 163023, 276294,
    276431, 273396, 271383, 278742, 278759, 278758, 276295, 271399, 109256, 138374, 138398, 138403, 138401, 109266,
    138373, 107008, 109226, 179629, 180369, 164103, 183855, 164695, 164362, 162745, 169757, 164335, 163621, 245299,
    254568, 260073, 245344, 254731, 251945, 249853, 244961, 253808, 258257, 247175, 247121, 247131, 247173, 152145,
    47673, 2452, 2362, 2355, 2420, 2357, 2354, 2353, 2451, 243359, 52817, 48229, 50513, 213545, 48230, 55546, 73031,
    46695, 48228, 50514, 114944, 228197, 148551, 148256, 204332, 153379, 149407, 151631, 152361, 159013, 149493, 173885,
    154197, 151107, 152088, 149193, 149199, 149600, 225234, 253903, 225979, 237594, 234260, 237598, 251668, 225986,
    241910, 230579, 226502, 226498, 222047, 227229, 226614, 225394, 225971, 146005, 150161, 150157, 150167, 147008,
    147005, 146999, 150147, 153969, 230769, 169257, 63973, 35116, 137948, 1532, 2091, 7940, 7937, 2089, 1531, 1534,
    1535, 1530, 103915, 221743, 17228, 86180, 26970, 25404, 25932, 19729, 11045, 21596, 10999, 10980, 11113, 28876,
    35630, 28561, 29464, 243734, 243731, 272505, 243732, 241949, 248069, 243563, 243730, 147291, 42241, 42245, 107517,
    63176, 84258, 136422, 147299, 189416, 42242, 233597, 233592, 239874, 233575, 188481, 239654, 233600, 233595, 233598,
    256176, 229488, 239875, 229486, 233574, 239752, 259768, 239869, 259765, 273164, 273574, 233599, 35946, 47750, 32103,
    35944, 16041, 15986, 18834, 153451, 153477, 153469, 158462, 155123, 153485, 110776, 62972, 62958, 110769, 63034,
    63027, 15306, 20858, 15144, 63023, 63031, 26911, 63019, 10055, 8197, 8171, 8168, 9722, 8198, 9723, 8200, 10046,
    10054, 10058, 233754, 128727, 130159, 142453, 130251, 128646, 127576, 128693, 123555, 127586, 123553, 124498,
    124493, 124487, 225313, 190868, 124503, 113378, 124474, 116957, 265586, 124823, 215312, 124511, 124579, 124495,
    15217, 15216, 15221, 17970, 18777, 15213, 25762, 28379, 32194, 61935, 61936, 88729, 26071, 26062, 26064, 113893,
    118080, 135387, 116441, 131819, 133875, 122695, 116475, 116455, 120708, 133877, 133873, 133737, 123959, 126099,
    121954, 138664, 127798, 116477, 116451, 116545, 135300, 123963, 135314, 135303, 193804, 114015, 125357, 179968,
    179995, 139187, 193762, 113846, 113821, 194001, 194002, 193994, 171314, 195220, 171313, 190665, 247647, 181755,
    171276, 181128, 181116, 190147, 181988, 186277, 191048, 255571, 181106, 171991, 171993, 182479, 172011, 205476,
    196040, 209266, 195228, 222884, 196042, 171315, 188497, 172012, 225603, 171994, 186522, 181127, 262571, 253584,
    258854, 258860, 258866, 258869, 263133, 258863, 258867, 258865, 263130, 258864, 254505, 258868, 258862, 254508,
    253597, 261697, 254502, 253598, 253601, 147971, 126006, 213713, 225879, 173106, 126396, 125853, 214428, 130800,
    127201, 131697, 126139, 128595, 125858, 126121, 126125, 130479, 125532, 125857, 125531, 131601, 131602, 132413,
    79084, 243362, 213549, 66361, 79088, 96140, 96139, 66366, 78936, 136173, 66355, 66367, 253307, 251023, 253333,
    250604, 251022, 35201, 53496, 128543, 3651, 2893, 2889, 2886, 2885, 241019, 240994, 193665, 244337, 241001, 16936,
    2400, 2403, 2401, 2398, 2376, 231710, 244122, 244110, 244135, 244120, 244333, 244121, 231706, 231701, 231696,
    231694, 150615, 193162, 150609, 153899, 136854, 136853, 150611, 150617, 41393, 8084, 41397, 8085, 8088, 8145, 8083,
    8140, 8142, 227686, 240544, 266684, 265828, 266728, 265825, 266551, 266946, 266455, 266831, 266446, 268754, 283457,
    283460, 283461, 283489, 283490, 251403, 286706, 286702, 286701, 285934, 287062, 288108, 287877, 279361, 235738,
    286293, 289392, 289395, 289400, 289342, 289507, 289505, 289740, 289742, 289743, 289744, 285930, 289803, 289802,
    289756, 289817, 289818, 289819, 289820, 289805, 289806, 289807, 289762, 289633, 286116, 289760, 290084, 290083,
    290082, 290181, 290339, 290344, 290341, 290316, 290256, 290271, 290194, 290190, 290197, 290200, 290385, 290386,
    290387, 290193, 290189, 290198, 290182, 290183, 290185, 290184, 290262, 290260, 290261, 290199, 290263, 290350,
    290348, 27513, 20238, 265915, 20438, 290764, 290762, 290742, 290930, 291142, 290964, 290976, 290962, 290952, 291356,
    290909, 290237, 290240, 290235, 291540, 291400, 291393, 291582, 291584, 291600, 291605, 290707, 290288, 290787,
    290785, 290704,
  ]),
  TWO_PLUS: new Set([
    // Food items that are only available to 2+ serving sizes
    282514, 283010, 286258, 286008, 283259, 283260, 283261, 283262,
  ]),
};

export const FLEXIBLE_SIDES_MP_ONE_SERVING_MAIN_FOOD_ITEM_IDS = new Set([
  ...FLEXIBLE_SIDES_MP_MAIN_FOOD_ITEM_IDS_BY_SERVING.ANY,
]);
export const FLEXIBLE_SIDES_MP_TWO_PLUS_SERVING_MAIN_FOOD_ITEM_IDS = new Set([
  ...FLEXIBLE_SIDES_MP_MAIN_FOOD_ITEM_IDS_BY_SERVING.ANY,
  ...FLEXIBLE_SIDES_MP_MAIN_FOOD_ITEM_IDS_BY_SERVING.TWO_PLUS,
]);

export const FLEXIBLE_SIDES_MP_MAIN_FOOD_ITEM_IDS = new Set([
  ...FLEXIBLE_SIDES_MP_MAIN_FOOD_ITEM_IDS_BY_SERVING.ANY,
  ...FLEXIBLE_SIDES_MP_MAIN_FOOD_ITEM_IDS_BY_SERVING.TWO_PLUS,
]);

export const MAINS_PLUS_SIDES_MP_SIDE_FOOD_ITEM_IDS_BY_CATEGORY: {
  [key: string]: Array<{ shefId: string; foodItemId: number }>;
} = {
  roti: [
    { shefId: 'karthika-k-1', foodItemId: 257545 },
    { shefId: 'anu-s-5', foodItemId: 2360 },
    { shefId: 'sunitha-p-1', foodItemId: 60031 },
    { shefId: 'sidhant-s-4', foodItemId: 239010 },
    { shefId: 'nidhi-v-4', foodItemId: 209937 },
    { shefId: 'harpreet-k-18', foodItemId: 157310 },
    { shefId: 'moms-b', foodItemId: 86102 },
    { shefId: 'shereen-d-1', foodItemId: 182119 },
    { shefId: 'moms-b', foodItemId: 63175 },
    { shefId: 'kamaljit-k', foodItemId: 47367 },
    { shefId: 'harpreet-k-18', foodItemId: 157351 },
    { shefId: 'nidhi-v-4', foodItemId: 155161 },
    { shefId: 'agzana-y-2', foodItemId: 225915 },
    { shefId: 'fahmida-l', foodItemId: 73094 },
    { shefId: 'divya-k-13', foodItemId: 76502 },
    { shefId: 'jyoti-a-11', foodItemId: 237679 },
    { shefId: 'rosie-k', foodItemId: 265757 },
    { shefId: 'nikita-c-3', foodItemId: 53019 },
    { shefId: 'rasika-c', foodItemId: 63168 },
    { shefId: 'madhuben-p', foodItemId: 269866 },
    { shefId: 'rasika-c', foodItemId: 180427 },
    { shefId: 'munmun-b-1', foodItemId: 214123 },
    { shefId: 'yamini-b-1', foodItemId: 209824 },
    { shefId: 'neetu-g-6', foodItemId: 249680 },
    { shefId: 'alekhya-k-2', foodItemId: 259815 },
    { shefId: 'rose-d-5', foodItemId: 190628 },
    { shefId: 'anita-b-1', foodItemId: 261848 },
    { shefId: 'noopur-g-6', foodItemId: 147545 },
    { shefId: 'sukhjeet-d', foodItemId: 33738 },
    { shefId: 'smitarani-p', foodItemId: 17381 },
    { shefId: 'punam-g', foodItemId: 176085 },
    { shefId: 'sukhjeet-d', foodItemId: 271774 },
    { shefId: 'sunita-b-12', foodItemId: 263940 },
    { shefId: 'virali-p', foodItemId: 219779 },
    { shefId: 'namita-j-2', foodItemId: 65913 },
    { shefId: 'jasmeet-s-1', foodItemId: 17340 },
    { shefId: 'paramvir-k', foodItemId: 236617 },
    { shefId: 'jasmeet-s-1', foodItemId: 17350 },
    { shefId: 'deepa-p-17', foodItemId: 188216 },
    { shefId: 'nasreen-v', foodItemId: 11443 },
  ],
  'basmati rice': [
    { shefId: 'wayne-x-1', foodItemId: 75479 },
    { shefId: 'wayne-x', foodItemId: 2407 },
    { shefId: 'moms-b', foodItemId: 147305 },
    { shefId: 'shreyaben-b', foodItemId: 258860 },
    { shefId: 'shreyaben-b', foodItemId: 258867 },
    { shefId: 'reena-b-6', foodItemId: 122760 },
    { shefId: 'nagalakshmi-v-1', foodItemId: 249552 },
    { shefId: 'priya-m-34', foodItemId: 128689 },
    { shefId: 'mandira-d-3', foodItemId: 188106 },
    { shefId: 'sidhant-s-4', foodItemId: 247949 },
    { shefId: 'mahi-s-1', foodItemId: 9771 },
    { shefId: 'shreyaben-b', foodItemId: 254512 },
    { shefId: 'shreyaben-b', foodItemId: 254507 },
    { shefId: 'himanshi-s-3', foodItemId: 239054 },
    { shefId: 'sophie-e-1', foodItemId: 202627 },
    { shefId: 'upinder-d', foodItemId: 100696 },
    { shefId: 'agzana-y-2', foodItemId: 225985 },
    { shefId: 'susan-p', foodItemId: 117628 },
    { shefId: 'anchal-k-1', foodItemId: 247995 },
    { shefId: 'yamini-b-1', foodItemId: 182593 },
    { shefId: 'rosie-k', foodItemId: 103067 },
    { shefId: 'nikita-c-3', foodItemId: 29056 },
    { shefId: 'prachi-p-24', foodItemId: 238231 },
    { shefId: 'revathi-s', foodItemId: 143341 },
    { shefId: 'madhuben-p', foodItemId: 269874 },
    { shefId: 'web-k', foodItemId: 267659 },
    { shefId: 'premprakash-n', foodItemId: 20062 },
    { shefId: 'ruchi-g-22', foodItemId: 258645 },
    { shefId: 'punam-g', foodItemId: 259790 },
    { shefId: 'ricky-h', foodItemId: 205730 },
    { shefId: 'smitarani-p', foodItemId: 17380 },
    { shefId: 'durga-s-5', foodItemId: 237581 },
    { shefId: 'durga-s-5', foodItemId: 235998 },
  ],
  'jeera rice': [
    { shefId: 'sukhdeep-k-2', foodItemId: 66363 },
    { shefId: 'balihar-s', foodItemId: 55543 },
    { shefId: 'yasmin-b-3', foodItemId: 171614 },
    { shefId: 'harpreet-k-18', foodItemId: 147013 },
    { shefId: 'sidhant-s-4', foodItemId: 247949 },
    { shefId: 'shreyaben-b', foodItemId: 254507 },
    { shefId: 'sneha-p-15', foodItemId: 126126 },
    { shefId: 'kamaljit-k', foodItemId: 25813 },
    { shefId: 'monika-j-1', foodItemId: 11625 },
    { shefId: 'anita-k-4', foodItemId: 9569 },
    { shefId: 'joyal-g', foodItemId: 4622 },
    { shefId: 'upinder-d', foodItemId: 100696 },
    { shefId: 'divya-k-13', foodItemId: 65404 },
    { shefId: 'jasneet-k', foodItemId: 34162 },
    { shefId: 'sujatha-r-7', foodItemId: 176164 },
    { shefId: 'rosie-k', foodItemId: 103069 },
    { shefId: 'anchal-k-1', foodItemId: 247618 },
    { shefId: 'nikita-c-3', foodItemId: 38311 },
    { shefId: 'seema-b-5', foodItemId: 72075 },
    { shefId: 'sakshi-a-5', foodItemId: 36265 },
    { shefId: 'sukhjeet-d', foodItemId: 20340 },
    { shefId: 'durga-s-5', foodItemId: 237581 },
  ],
  naan: [
    { shefId: 'wayne-x', foodItemId: 2408 },
    { shefId: 'wayne-x-1', foodItemId: 8092 },
  ],
  'jasmine rice': [
    { shefId: 'helen-c-15', foodItemId: 124586 },
    { shefId: 'theint-e', foodItemId: 77826 },
    { shefId: 'issara-t', foodItemId: 2746 },
    { shefId: 'victor-s-3', foodItemId: 109225 },
    { shefId: 'thazin-h', foodItemId: 85751 },
    { shefId: 'ringo-t', foodItemId: 166467 },
  ],
};

export const MAINS_PLUS_SIDES_MP_SIDE_FOOD_ITEM_IDS = new Set(
  Object.values(MAINS_PLUS_SIDES_MP_SIDE_FOOD_ITEM_IDS_BY_CATEGORY)
    .flat()
    .map(({ foodItemId }) => foodItemId)
);

// If you're adding to this list, make sure to remove the dishes from the mains-plus-sides main list
export const FLEXIBLE_SIDES_MP_STAPLE_FOOD_ITEM_IDS_BY_CATEGORY: {
  [key: string]: Array<{ shefId: string; foodItemId: number }>;
} = {
  'basmati rice': [
    { shefId: 'moms-b', foodItemId: 147305 },
    { shefId: 'kaveri-p-2', foodItemId: 265752 },
    { shefId: 'agzana-y-2', foodItemId: 225985 },
    { shefId: 'anna-w-4', foodItemId: 289813 },
    { shefId: 'justin-b-49', foodItemId: 290392 },
    { shefId: 'itay-s', foodItemId: 290452 },
  ],
  'brown rice': [{ shefId: 'aji-b', foodItemId: 175206 }],
  'jasmine rice': [
    { shefId: 'ringo-t', foodItemId: 166467 },
    { shefId: 'aji-b', foodItemId: 174632 },
    { shefId: 'dmytro-m', foodItemId: 108199 },
    { shefId: 'manida-t', foodItemId: 60805 },
    { shefId: 'francis-l-5', foodItemId: 218347 },
    { shefId: 'sombo-p', foodItemId: 283507 },
    { shefId: 'woong-c-1', foodItemId: 289975 },
  ],
  naan: [
    { shefId: 'wayne-x', foodItemId: 2408 },
    { shefId: 'wayne-x-1', foodItemId: 8092 },
    { shefId: 'fouzia-a', foodItemId: 39260 },
  ],
  other: [
    { shefId: 'ringo-t', foodItemId: 255393 },
    { shefId: 'manida-t', foodItemId: 66437 },
    { shefId: 'sophie-e-1', foodItemId: 76750 },
    { shefId: 'naina-a', foodItemId: 15993 },
    { shefId: 'pooja-b-5', foodItemId: 8217 },
    { shefId: 'priya-m-34', foodItemId: 130801 },
    { shefId: 'dennis-h-13', foodItemId: 283503 },
    { shefId: 'dennis-h-13', foodItemId: 283502 },
    { shefId: 'dennis-h-13', foodItemId: 283500 },
    { shefId: 'dennis-h-13', foodItemId: 283504 },
    { shefId: 'lindsay-d-4', foodItemId: 286741 },
    { shefId: 'lindsay-d-4', foodItemId: 286742 },
    { shefId: 'lindsay-d-4', foodItemId: 286743 },
    { shefId: 'lindsay-d-4', foodItemId: 286744 },
    { shefId: 'tomoko-p', foodItemId: 264670 },
    { shefId: 'tomoko-p', foodItemId: 286324 },
    { shefId: 'zoe-h-2', foodItemId: 289509 },
    { shefId: 'woong-c-1', foodItemId: 289973 },
    { shefId: 'woong-c-1', foodItemId: 289976 },
    { shefId: 'woong-c-1', foodItemId: 289978 },
    { shefId: 'woong-c-1', foodItemId: 289973 },
    { shefId: 'anna-w-4', foodItemId: 289812 },
    { shefId: 'noah-g-14', foodItemId: 289683 },
    { shefId: 'skye-h', foodItemId: 290317 },
    { shefId: 'kevin-r-34', foodItemId: 290277 },
    { shefId: 'kevin-r-34', foodItemId: 290274 },
    { shefId: 'kevin-r-34', foodItemId: 290273 },
    { shefId: 'kevin-r-34', foodItemId: 290267 },
    { shefId: 'itay-s', foodItemId: 290454 },
    { shefId: 'itay-s', foodItemId: 290516 },
  ],
  roti: [
    { shefId: 'haripriya-b', foodItemId: 225722 },
    { shefId: 'harpreet-k-18', foodItemId: 157310 },
    { shefId: 'moms-b', foodItemId: 63175 },
    { shefId: 'rupa-d-1', foodItemId: 15222 },
    { shefId: 'swapnali-p', foodItemId: 2895 },
    { shefId: 'ashar-q', foodItemId: 205414 },
    { shefId: 'fahmida-l', foodItemId: 73094 },
    { shefId: 'agzana-y-2', foodItemId: 225915 },
  ],
  'jeera rice': [],
};

export const FLEXIBLE_SIDES_MP_STAPLE_FOOD_ITEM_IDS = new Set(
  Object.values(FLEXIBLE_SIDES_MP_STAPLE_FOOD_ITEM_IDS_BY_CATEGORY)
    .flat()
    .map(({ foodItemId }) => foodItemId)
);

// If you're adding to this list, make sure to remove the dishes from the mains-plus-sides main list
export const FLEXIBLE_SIDES_MP_SIDE_FOOD_ITEM_IDS: Set<number> = new Set([
  283173, 283174, 282845, 282466, 282465, 282517, 282521, 282518, 282523, 247375, 283781, 283783, 283784, 283782,
  283741, 283498, 283505, 283506, 283266, 283264, 283265, 283256, 283257, 283296, 283258, 196771, 185803, 198531,
  262019, 42595, 172158, 175195, 43868, 43877, 115183, 218349, 45256, 272373, 268949, 24679, 63615, 149203, 183499,
  157856, 249429, 202623, 74130, 74129, 76778, 74137, 78643, 183874, 167287, 170609, 183857, 163622, 148249, 182686,
  233583, 233615, 233614, 113023, 41389, 265672, 265673, 265746, 283491, 283492, 283499, 286727, 286729, 286734, 286735,
  286736, 286294, 286329, 289401, 289599, 289471, 289470, 289546, 289510, 289514, 289513, 289745, 289747, 289804,
  289821, 289822, 289824, 289809, 289810, 289811, 289761, 289763, 289764, 289937, 290087, 290086, 290349, 290314,
  290318, 290310, 290383, 290382, 290388, 290389, 290390, 290391, 290196, 290353, 290394, 290384, 290187, 290186,
  290265, 290399, 290352, 290398, 290797, 290767, 290796, 290603, 290912, 291370, 291369, 291368, 291372, 291371,
  290788, 290880, 290881, 290879, 291606, 291608,
]);

export const STAPLE_DIETARY_TAGS_TO_IGNORE = [
  DietaryTag.Paleo,
  DietaryTag.Whole30,
  DietaryTag.DairyFree,
  DietaryTag.Halal,
  DietaryTag.LowFodmap,
  DietaryTag.Kosher,
];

export const ALL_STAPLE_TYPE_FOOD_ITEMS = new Set([
  ...FLEXIBLE_SIDES_MP_STAPLE_FOOD_ITEM_IDS,
  ...MAINS_PLUS_SIDES_MP_SIDE_FOOD_ITEM_IDS,
]);
export const REGION_ID_TO_OPS_RANKED_SHEF_IDS: Record<number, string[]> = {
  1: [
    'rashida-m',
    'arthur-c-9',
    'maen-r-1',
    'ana-w-4',
    'maggi-h ',
    'thu-ba-c',
    'ringo-t',
    'kelly-l-3',
    'kwesi-r',
    'yukako-k-2',
    'nicholas-b-21',
    'dmytro-m',
    'francesca-g-1',
    'victor-s-3',
    'robert-m-86',
    'andrew-e-14',
    'woong-c-1',
    'zhidu-l-1',
    'heder-l',
    'sophie-e-1',
    'francis-l-5',
    'sevim-1',
    'ruben-g',
    'joina-l',
    'manida-t',
    'nan-s',
    'aji-b',
    'kamaljit-k',
    'parvinder-s',
    'sneha-p-15',
    'geetwani-r',
    'haripriya-b',
    'mukta-b-1',
    'sukhdeep-k-2',
    'anu-s-5',
    'wayne-x',
    'moms-b',
    'balihar-s',
    'seema-a',
    'wayne-x-1',
    'sarada-a',
    'rumee-s',
    'nidhi-v-4',
    'priya-n-24',
    'rupa-d-1',
    'mahi-s-1',
    'sadaf-r',
    'pooja-b-5',
    'shereen-d-1',
    'shreyaben-b',
    'swapnali-p',
    'shahid-r-1',
    'akanksha-j-8',
    'priya-m-34',
    'sumathi-p-1',
    'naina-a',
    'harpreet-k-18',
    'manpreet-s-25',
    'anand-a-23',
    'amit-r-33',
    'manjula-t',
  ],

  2: [
    'david-r-90',
    'alissa-h-3',
    'chyanne-c',
    'skye-h',
    'lindsay-d-4',
    'zoe-h-2',
    'ross-e-3',
    'drew-k-4',
    'noah-g-14',
    'kimberly-h-26',
    'skye-h-1',
    'itay-s',
    'ayah-a-4',
    'roy-y-4',
    'justin-b-49',
    'careem-g',
    'kevin-r-34',
    'sean-l-34',
    'matthew-p-32',
    'sombo-p',
    'gary-h-12',
    'dennis-h-13',
    'jordan-f-10',
    '-259811',
    'tomoko-p',
    'ritu-m-9',
    'fahmida-l',
    'agzana-y-2',
    'fouzia-a',
    'kaveri-p-2',
    'ashar-q',
  ],

  5: [],
  6: [],
};

export const FOOD_ITEMS_HIDDEN_FROM_MKTP: Set<number> = new Set([
  282459, 282461, 282462, 282850, 282845, 282466, 282465, 282514, 283010, 283259, 283261, 283262, 283260, 283266,
  283264, 283265, 283166, 283161, 283163, 283170, 283172, 286706, 286702, 286701, 285934, 286727, 286729, 286734,
  286735, 286736, 286741, 286742, 286743, 286744, 289392, 289395, 289400, 289401, 289599, 289471, 289470, 289342,
  289507, 289505, 289546, 289510, 289514, 289513, 289509, 289740, 289742, 289743, 289744, 289745, 289747, 285930,
  286008, 289803, 289802, 289804, 289973, 289976, 289975, 289978, 289756, 289817, 289818, 289819, 289820, 289821,
  289822, 289824, 289805, 289806, 289807, 289762, 289809, 289810, 289811, 289812, 289813, 289633, 286116, 289760,
  289761, 289763, 289764, 289937, 289683, 290087, 290086, 290084, 290083, 290082, 290181, 290339, 290344, 290341,
  290349, 290316, 290256, 290314, 290317, 290271, 290318, 290194, 290190, 290197, 290310, 290383, 290382, 290200,
  290385, 290386, 290387, 290388, 290389, 290390, 290391, 290392, 290182, 290183, 290185, 290184, 290384, 290187,
  290186, 290452, 290454, 290262, 290260, 290261, 290199, 290263, 290277, 290274, 290273, 290265, 290267, 290399,
  290352, 290398, 290350, 290348, 290797, 290767, 290796, 290764, 290762, 290742, 290516, 290930, 291142, 290964,
  290976, 290962, 290952, 290603, 291356, 290909, 290912, 290237, 290240, 290235, 291370, 291369, 291368, 291372,
  291371, 291540, 291400, 291393, 291582, 291584, 291600, 291605, 290707, 290288, 290787, 290785, 290704, 290788,
  290880, 290881, 290879, 291606, 291608,
]);

export const SHEF_MAXIMUM_CAPACITY_PERCENTAGE = 0.75;

// Combines all shef IDs from meal plan related constants, ensuring uniqueness
export const ALL_MEAL_PLAN_SHEFS: Set<string> = (() => {
  // Get shef IDs from REGION_ID_TO_OPS_RANKED_SHEF_IDS
  const regionShefIds = Object.values(REGION_ID_TO_OPS_RANKED_SHEF_IDS).flat();

  // Get shef IDs from FLEXIBLE_SIDES_MP_STAPLE_FOOD_ITEM_IDS_BY_CATEGORY
  const flexibleSidesShefIds = Object.values(FLEXIBLE_SIDES_MP_STAPLE_FOOD_ITEM_IDS_BY_CATEGORY)
    .flat()
    .map(({ shefId }) => shefId);

  // Get shef IDs from MAINS_PLUS_SIDES_MP_SIDE_FOOD_ITEM_IDS_BY_CATEGORY
  const mainsPlusSidesShefIds = Object.values(MAINS_PLUS_SIDES_MP_SIDE_FOOD_ITEM_IDS_BY_CATEGORY)
    .flat()
    .map(({ shefId }) => shefId);

  // Combine all arrays and create a Set to ensure uniqueness
  return new Set([...regionShefIds, ...flexibleSidesShefIds, ...mainsPlusSidesShefIds]);
})();

export const SOUTH_ASIAN_REGIONAL_CUISINE_CATEGORIES = new Set([
  'BIHARI',
  'CHENNAI',
  'DELHI',
  'LAHORI',
  'TIBETAN',
  'BHUTANESE',
  'MALDIVIAN',
  'MALVANI',
  'MARATHI',
  'MUMBAI',
  'JAIN',
  'BENGALI',
  'ODISHA',
  'EAST_INDIAN',
  'NORTH_INDIAN',
  'INDO_CHINESE',
  'SOUTH_INDIAN',
  'WEST_INDIAN',
  'KOLHAPURI',
  'MARWARI',
  'SINDHI',
  'CHHATTISGARHI',
  'LUCKNOWI',
  'KASHMIRI',
  'RAJASTHANI',
  'AWADHI',
  'MUGHLAI',
  'PUNJABI',
  'HIMALAYAN',
  'PAKISTANI',
  'INDIAN',
  'BANGLADESHI',
  'SRI_LANKAN',
  'NEPALESE',
  'KARNATAKA',
  'CHETTINAD',
  'ANDHRA',
  'COIMBATORE',
  'MANGALOREAN',
  'COORG',
  'TAMILIAN',
  'KERALA',
  'UDUPI',
  'HYDERABADI',
  'MYSORE',
  'GUJARATI',
  'MAHARASHTRIAN',
  'KONKANI',
  'GOAN',
]);

export const KID_FRIENDLY_MEAL_PLAN_FOOD_ITEM_IDS = new Set<number>([
  274438, 282497, 283174, 282459, 282466, 282465, 282516, 282517, 282521, 247909, 275151, 251402, 188767, 283259,
  283260, 283250, 283256, 283257, 283494, 283505, 282471, 283740, 281777, 283784, 73959, 80528, 173764, 81353, 231102,
  227686, 240544, 185786, 252219, 185769, 283460, 283491, 283499, 286706, 285934, 286729, 286734, 286735, 286258,
  286294, 2682, 2673, 2665, 2672, 10316, 175206, 174632, 43868, 43877, 283166, 283161, 283172, 157892, 218347, 270664,
  218412, 45256, 21875, 73305, 130168, 144911, 66437, 272373, 24679, 209652, 207694, 154566, 149897, 181628, 149203,
  157856, 166467, 255393, 254475, 249429, 251554, 202623, 76749, 140119, 74256, 131226, 74130, 74129, 76778, 74137,
  78643, 76750, 278742, 278759, 278758, 271399, 138403, 2452, 2353, 2451, 48228, 149407, 154197, 148249, 150167, 146999,
  2091, 7937, 147971, 173106, 127201, 125858, 131601, 213549, 96140, 66366, 2376, 231706, 193162, 150617, 8084, 8083,
  8140, 289392, 289400, 289470, 289683, 289342, 289514, 289509, 289740, 289743, 289818, 289805, 289807, 289760, 290084,
  290082, 290181, 290341, 290316, 290256, 290271, 290386, 290387, 290189, 290182, 290183, 290185, 290184, 290350,
  290348,
]);
